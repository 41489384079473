export const routes = {
  allocations: {
    confirmAllocationDraft: (allocationEventId: string) => `/allocations/events/${allocationEventId}/confirm`,
    createAllocationDraft: '/allocations/events/draft',
    downloadAllocationEvents: (allocationEventId: string) => `/allocations/events/${allocationEventId}/download`,
    finalizeAllocation: (id: string) => `/allocations/events/${id}/finalize`,
    getAllocationEvents: '/allocations/events',
    getAvailableVolumes: (allocationEventId: string) => `/allocations/events/${allocationEventId}/available-volumes`,
    getRedeemedCertificateBundles: (id: string) => `/allocations/events/${id}/redeemed-certificate-bundles`,
    removeAllocationDraftConsumer: (allocationEventId: string, consumerId: string) =>
      `/allocations/events/${allocationEventId}/consumers/${consumerId}`,
    undoAllocation: (id: string) => `/allocations/events/${id}/undo`,
    updateConsumptionAllocationDraft: (allocationEventId: string) =>
      `/allocations/events/${allocationEventId}/consumption-driven/allocate`,
    updateProductionAllocationDraft: (allocationEventId: string, consumerId: string) =>
      `/allocations/events/${allocationEventId}/allocate/${consumerId}`,
  },
  consumers: {
    createConsumer: '/consumers',
    deleteConsumer: (id: string) => `/consumers/${id}`,
    getConsumer: (id: string) => `/consumers/${id}`,
    getConsumerMeteringPointStates: (id: string) => `/consumers/${id}/metering-point-states`,
    getConsumerMeteringPoints: (id: string) => `/consumers/${id}/metering-points`,
    getConsumers: '/consumers',
    updateConsumer: (id: string) => `/consumers/${id}`,
    upsertConsumersCsv: '/consumers/create-or-update-many-csv',
  },
  endConsumers: {
    getConsumerReports: '/endconsumers/reports',
  },
  import: {
    getUploadedConsumptionFileStatus: (id: string) => `/multipoint-consumption-files/${id}`,
    getUploadedProductionFileStatus: (id: string) => `/multipoint-production-files/${id}`,
    uploadConsumptionFile: '/multipoint-consumption-files/upload',
    uploadProductionFile: '/multipoint-production-files/upload',
  },
  meteringPoints: {
    createMeteringPoint: '/metering-points',
    getMeteringPointStatesCsv: '/metering-points/states/export/csv',
    getMeteringPoints: '/metering-points',
    updateMeteringPoint: (id: string) => `/metering-points/${id}`,
  },
  metrics: {
    getPerimeter: '/metrics/perimeter',
    getPortfolioOverview: '/metrics/portfolio/overview',
    getConsumerOverview: '/metrics/consumer/overview',
    getConsumerCarbon: '/metrics/consumer/carbon',
  },
  certificateBundles: {
    getCertificateBundles: '/certificate-bundle',
    uploadCertificates: '/certificate-bundle/upload-certificates',
    uploadOfgemCertificates: '/certificate-bundle/upload-ofgem-certificates',
  },
  ppas: {
    createPpa: '/ppas',
    deletePpa: (id: string) => `/ppas/${id}`,
    getPpa: (id: string) => `/ppas/${id}`,
    getPpas: '/ppas',
  },
  productionDevices: {
    createProductionDevice: '/assets',
    deleteProductionDevice: (id: string) => `/assets/${id}`,
    getProductionDevice: (id: string) => `/assets/${id}`,
    getProductionDevices: '/assets',
    updateProductionDevice: (id: string) => `/assets/${id}`,
    upsertProductionDevicesCsv: '/assets/create-or-update-many-csv',
  },
  reports: {
    deleteReport: (id: string) => `/reports/${id}`,
    downloadReport: (id: string) => `/reports/${id}/download`,
    generateReport: '/reports/bulk',
    getReports: '/reports',
  },
  tags: {
    getTags: '/tags/consumption',
  },
  termsAndConditions: {
    acceptTermsAndConditions: (id: string, userEmail: string) =>
      `/terms-and-conditions/${id}/users/${userEmail}/accept`,
    downloadTermsAndConditions: (id: string) => `/terms-and-conditions/${id}/download`,
    getTermsAndConditions: '/terms-and-conditions',
    getTermsAndConditionsUserAcceptance: (userEmail: string) => `/terms-and-conditions/users/${userEmail}/check`,
  },
  userConfig: {
    getUserConfig: '/user-config',
  },
} as const;

export type Route = (typeof routes)[keyof typeof routes];
